/**
 * @param {{ filters: {} }} store
 * @param {{}} filters
 */
export const setFilters = (store, filters) => {
  Object.keys(filters).forEach((key) => {
    if (key in store.filters) {
      store.filters[key] = filters[key]
    }
  })
}
