import React from "react"
import {
  CardContent,
  Chip,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material"
import { SubCard } from "shared/ui/berry-jass"
import { useFiltersConfiguration } from "entities/user-settings"

/**
 * @template T
 * @param {{name: string; value: T | T[]; label: React.ReactNode; color?: string; getChipLabel?: (T) => string | number; onDelete: Function; configKey?: string; hide?: boolean;}} props
 */
export const FilterChips = ({
  name,
  value,
  label,
  color = "light",
  configKey = null,
  hide = false,
  getChipLabel = (x) => x,
  onDelete
}) => {
  const configuration = useFiltersConfiguration(configKey)
  const theme = useTheme()
  const matchDownMD = useMediaQuery(theme.breakpoints.down("lg"))
  const val = value instanceof Array ? value : [value]

  if (!value || !val.length || hide || configuration.includes(name)) {
    return null
  }

  return (
    <Grid item>
      <SubCard
        sx={{
          borderColor: "#DFDFDF",
          width: "fit-content"
        }}
        content={false}
      >
        <CardContent sx={{ py: "8px !important", p: 2 }}>
          <Grid container spacing={1} alignItems="center">
            <Grid item sx={{ mr: 1 }}>
              <Typography variant="h6">{label}</Typography>
            </Grid>
            {val.map((item, index) => {
              const handleDelete = onDelete ? () => onDelete(item) : null

              return (
                <Grid item key={index}>
                  <Chip
                    sx={{ height: "24px", fontSize: "12px", fontWeight: "500" }}
                    size={matchDownMD ? "medium" : undefined}
                    label={getChipLabel(item)}
                    onDelete={handleDelete}
                    color={color}
                  />
                </Grid>
              )
            })}
          </Grid>
        </CardContent>
      </SubCard>
    </Grid>
  )
}
