import React from "react"
import omit from "lodash/omit"
import isEqual from "lodash/isEqual"

import { Grid } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { useFiltersConfiguration } from "entities/user-settings"

export const AppliedFiltersContainer = ({
  filters,
  initialFilters,
  configKey = null,
  children
}) => {
  const configuration = useFiltersConfiguration(configKey)
  const theme = useTheme()

  const hideFilterChips = isEqual(
    omit(initialFilters, configuration),
    omit(filters, configuration)
  )
  if (hideFilterChips) {
    return null
  }

  return (
    <Grid
      container
      gap={2}
      data-testid="applied-filters"
      alignItems="center"
      sx={{
        [theme.breakpoints.down("md")]: {
          flexWrap: "nowrap",
          overflowX: "scroll"
        },
        pb: 0,
        "& > .MuiGrid-item": { pb: "4px", pt: 0 }
      }}
    >
      {children}
    </Grid>
  )
}
