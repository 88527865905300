import { useMutation } from "@tanstack/react-query"
import { useDispatch } from "react-redux"
import { setUserSettings } from "store/modules/common/auth"
import client from "utils/client"

/**
 * @return {import("@tanstack/react-query").UseMutationResult<any, unknown, any, unknown>}
 */
export const useUpdateUserSettings = () => {
  const dispatch = useDispatch()

  return useMutation({
    mutationFn: async (data) => {
      const response = await client.put("/user_settings.json", {
        user_settings: data
      })
      return response.data
    },
    onSuccess: ({ user_settings }) => {
      dispatch(setUserSettings(user_settings))
    }
  })
}
