import useUser from "hooks/useUser"
import { useLocation } from "react-router"

/** @returns {string[]} */
export const useFiltersConfiguration = (configKey = null) => {
  const user = useUser()
  const defaultFiltersConfigurationKey = useDefaultFitlersConfigurationKey()

  return (
    user.settings.filter_configuration?.[
      configKey || defaultFiltersConfigurationKey
    ] ?? []
  )
}

export const useDefaultFitlersConfigurationKey = () => {
  return useLocation().pathname
}
