import intersection from "lodash/intersection"

/**
 * @param {number} a
 * @param {number} b
 * @returns {number}
 */
export const comparePercent = (a, b) => {
  if (!b) {
    return null
  }

  return ((a - b) / b) * 100
}

/**
 * @param {Array} arr
 * @param {Array} subArr
 */
export const includesArray = (arr, subArr) => {
  return intersection(arr, subArr).length === subArr.length
}
