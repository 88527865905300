import React, { memo } from "react"

import { useTheme } from "@mui/material/styles"
import Chart from "react-apexcharts"
import {
  Box,
  Grid,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material"
import MainCard from "shared/ui/berry-jass/MainCard"

/**
 * From berry RevenueCard
 *
 * @param {object} props
 * @param {React.ReactNode} props.primary
 * @param {React.ReactNode} props.secondary
 * @param {React.ReactNode} [props.secondarySub]
 * @param {React.ReactNode} props.content
 * @param {React.ReactNode} [props.iconPrimary]
 * @param {React.ReactNode} [props.loading]
 * @param {string} [props.balloonsColor]
 * @param {import("@mui/material").SxProps} [props.sx]
 * @param {ApexChart} [props.chartProps]
 * @param {'medium' | 'small'} [props.size]
 */
const BigComparisonCard = ({
  primary,
  secondary,
  secondaryHead,
  secondarySub,
  content,
  iconPrimary,
  loading,
  balloonsColor,
  chartProps,
  sx,
  size = "medium",
  ...rest
}) => {
  const theme = useTheme()
  const matchDownXs = useMediaQuery(theme.breakpoints.down("sm"))

  const showBalloons = !!balloonsColor

  return (
    <MainCard
      border={false}
      content={false}
      sx={{
        position: "relative",
        "&:after": {
          display: showBalloons ? "initial" : "none",
          content: '""',
          position: "absolute",
          width: 210,
          height: 210,
          backgroundColor: balloonsColor,
          borderRadius: "50%",
          zIndex: 1,
          top: -85,
          right: -95,
          opacity: 0.2,
          [theme.breakpoints.down("sm")]: {
            top: -105,
            right: -140
          }
        },
        "&:before": {
          display: showBalloons ? "initial" : "none",
          content: '""',
          position: "absolute",
          zIndex: 1,
          width: 210,
          height: 210,
          backgroundColor: balloonsColor,
          borderRadius: "50%",
          top: -125,
          right: -15,
          opacity: 0.1,
          [theme.breakpoints.down("sm")]: {
            top: -155,
            right: -70
          }
        },
        ...sx
      }}
      {...rest}
    >
      <Box sx={{ px: 2, py: 2 }}>
        <Typography
          variant="body2"
          sx={{
            position: "absolute",
            right: 13,
            top: 14,
            color: "#3A3841",
            "&> svg": { width: 100, height: 100, opacity: "0.5" },
            [theme.breakpoints.down("sm")]: {
              top: 13,
              "&> svg": { width: 80, height: 80 }
            }
          }}
        >
          {iconPrimary}
        </Typography>
        <Grid container direction={matchDownXs ? "column" : "row"} spacing={2}>
          <Grid item xs={12} pb={0.5}>
            <Typography variant="h4" color="inherit" lineHeight={1}>
              {primary}
            </Typography>
          </Grid>
          <Grid item mt={size === "small" ? 0 : 3} pb={1.5} xs={12}>
            <Grid container>
              <Grid item xs={chartProps ? 6 : 12}>
                {secondaryHead && (loading ? <Skeleton /> : secondaryHead)}
                <Stack
                  spacing={1}
                  mb={size === "small" ? 0 : 1}
                  direction="row"
                  alignItems="flex-end"
                >
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={size === "small" ? 32 : 40}
                    />
                  ) : (
                    <Typography
                      variant="h1"
                      color="inherit"
                      fontSize={size === "small" ? "1.5rem" : "2.125rem"}
                      lineHeight={1}
                    >
                      {secondary}
                    </Typography>
                  )}
                  {!loading && secondarySub}
                </Stack>

                {content &&
                  (loading ? (
                    <Skeleton height={20} />
                  ) : (
                    <Typography
                      pt={1}
                      variant={size === "small" ? "h4" : "h3"}
                      fontWeight={400}
                      color="inherit"
                      lineHeight="20px"
                    >
                      {content}
                    </Typography>
                  ))}
              </Grid>
              {chartProps && (
                <Grid
                  item
                  xs={6}
                  sx={{
                    div: {
                      zIndex: 2
                    },
                    svg: {
                      overflow: "visible"
                    }
                  }}
                >
                  {loading ? (
                    <Skeleton
                      height={chartProps.height}
                      variant="rectangular"
                    />
                  ) : (
                    <Chart {...chartProps} />
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </MainCard>
  )
}

export default memo(BigComparisonCard)
