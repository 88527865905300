import React from "react"
import { Box, CircularProgress, Typography } from "@mui/material"

export const CircularProgressWithLabel = ({ current, total, ...props }) => {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <Box position="absolute" color="#DFDFDF">
        <CircularProgress
          variant="determinate"
          value={100}
          {...props}
          color="inherit"
        />
      </Box>
      <CircularProgress
        variant="determinate"
        value={(current / total) * 100}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: "text.secondary" }}
        >
          {current}/{total}
        </Typography>
      </Box>
    </Box>
  )
}
