import React from "react"
import { useTheme } from "@mui/material/styles"
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  listItemTextClasses,
  Skeleton,
  Tooltip,
  Typography
} from "@mui/material"

import MainCard from "./MainCard"

const SmallComparisonCard = ({
  icon,
  primaryText,
  primarySubText = null,
  secondaryText,
  loading,
  color,
  ...rest
}) => {
  const theme = useTheme()

  return (
    <MainCard border={false} content={false} {...rest}>
      <Box sx={{ p: 2, py: "13px" }}>
        <List
          sx={{
            py: 0,
            [`& .${listItemTextClasses.root}`]: {
              margin: "0 !important"
            }
          }}
        >
          <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
            <ListItemAvatar>
              {loading ? (
                <Skeleton variant="rectangular" width={32} height={32} />
              ) : (
                <Avatar
                  variant="rounded"
                  sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.mediumAvatar,
                    backgroundColor:
                      color === "primary"
                        ? theme.palette.primary.light
                        : theme.palette.secondary.light,
                    color:
                      color === "primary"
                        ? theme.palette.primary.main
                        : theme.palette.secondary.main
                  }}
                >
                  {icon}
                </Avatar>
              )}
            </ListItemAvatar>
            <ListItemText
              primary={
                loading ? (
                  <Skeleton variant="rectangular" height={16} />
                ) : (
                  <Typography variant="h5">
                    {primaryText}{" "}
                    <Box fontWeight="normal" component="span">
                      {primarySubText}
                    </Box>
                  </Typography>
                )
              }
              secondary={
                loading ? (
                  <Skeleton variant="text" height={12} />
                ) : (
                  <Tooltip placement="top" title={secondaryText}>
                    <Typography
                      noWrap
                      lineHeight={1}
                      variant="subtitle2"
                      sx={{ color: theme.palette.grey[500] }}
                    >
                      {secondaryText}
                    </Typography>
                  </Tooltip>
                )
              }
            />
          </ListItem>
        </List>
      </Box>
    </MainCard>
  )
}

export default SmallComparisonCard
